























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { VPopover } from 'v-tooltip'

import { FilterOptions } from '../../../../../../contexts'

import { FilterList } from '../../../../molecules/FilterList'
import { FilterPopoverProps } from './FilterPopover.contracts'
import { Filter } from '@movecloser/front-core'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<FilterPopover>({
  name: 'FilterPopover',
  components: {
    FilterList,
    UiTooltip: VPopover
  }
})
export class FilterPopover extends Vue {
  @Prop({ required: true, type: Object })
  public icons!: Record<string, string>

  @Prop({ required: true, type: Object })
  public items!: FilterOptions

  @Prop({ required: false, type: Boolean, default: false })
  public isMulti!: boolean

  @Prop({ required: true, default: null })
  public filter!: FilterPopoverProps['filter']

  @Prop({ required: true, type: String })
  public queryParam!: string

  @Prop({ required: false, type: String })
  public title?: string

  @Prop({ required: true, type: String })
  public trigger!: string

  @Prop({ required: false, type: String })
  public triggerClass?: string

  public open: boolean = false
  public currentFilter: FilterPopoverProps['filter'] = this.filter ?? null

  public popoverConfig = {
    autoHide: true,
    placement: 'bottom-start',
    popoverClass: 'FilterPopover__content',
    popperOptions: {
      modifiers: {
        flip: {
          enabled: false
        }
      }
    }
  }

  public get count (): string {
    if (!this.isMulti) {
      return ''
    }

    if (!this.currentFilter) {
      return '0'
    }

    if (!Array.isArray(this.currentFilter)) {
      throw new Error('Multi filter has to be an array')
    }

    return this.currentFilter.length.toString()
  }

  public apply () {
    this.$emit('change', this.currentFilter)
    this.open = false
  }

  public discard () {
    this.open = false
    this.currentFilter = this.filter
  }

  public setFilter (newFilter: Filter) {
    this.currentFilter = newFilter
  }

  public toggle () {
    this.open = !this.open
  }

  @Watch('currentFilter')
  private change () {
    if (!this.isMulti) {
      this.apply()
    }
  }

  @Watch('filter')
  private update () {
    this.currentFilter = this.filter
  }
}

export default FilterPopover
