
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  composeQueryParams,
  parseQueryParams
} from '@movecloser/front-core/lib/services/filter-parser'
import {
  FilterParams,
  FiltersConfig,
  QueryParams
} from '@movecloser/front-core/lib/contracts/filter-parser'
import { Location } from 'vue-router/types/router'

import { FilterParamConfig } from '../../contexts'

/**
 * Handlers for query operations
 *
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({ name: 'FiltersHandlerMixin' })
export class FiltersHandlerMixin extends Vue {
  @Prop({ required: false, type: Object })
  public override!: QueryParams

  private overrideParams: FiltersConfig = this.override ? parseQueryParams(this.override) : {}

  protected initFilters (params: FilterParamConfig[], query: QueryParams) {
    // initialize queryParams with empty values for each available param
    const filters = this.clearFilters(params)

    if (!query) {
      return filters
    }

    const parsedQuery = parseQueryParams(query)

    // set values retrieved from query
    Object.keys(parsedQuery).forEach(param => {
      if (param in filters) {
        const value = parsedQuery[param]
        if (Array.isArray(filters[param])) {
          if (typeof value !== 'object') {
            throw new Error('Filter of type multi with wrong type')
          }
          filters[param] = Array.isArray(value) ? value : [value]
        } else {
          filters[param] = parsedQuery[param] ?? ''
        }
      }
    })

    return filters
  }

  protected updateFilter (
    filters: FiltersConfig | null,
    param: string,
    value: string
  ): FiltersConfig {
    return { ...filters, [param]: value }
  }

  protected updateAllFilters (
    filters: FiltersConfig | null,
    newFilters: FiltersConfig
  ): FiltersConfig {
    return { ...filters, ...newFilters }
  }

  public removeFilter (
    filters: FiltersConfig | null,
    params: FilterParamConfig[],
    param: string,
    value: string | number | boolean
  ): FiltersConfig | null {
    if (!filters) {
      return filters
    }

    const paramConfig = this.getParamConfig(params, param)

    if (!paramConfig) {
      throw new Error('Trying to remove a parameter that doesn\'t exist in this FilterConfig')
    }

    const isMulti = paramConfig.isMulti
    let newParamFilter = filters[param]

    if (isMulti) {
      newParamFilter = (newParamFilter as FilterParams[]).filter(f => f.value.toString() !== value.toString())

      if (newParamFilter.length) {
        delete newParamFilter[0].conjunction
      }
    } else {
      newParamFilter = ''
    }

    return { ...filters, [param]: newParamFilter }
  }

  private translateQueryParamsToQuery (queryParams: QueryParams): Location['query'] {
    return Object.keys(queryParams).reduce((prev, param) => ({
      ...prev,
      [param]: queryParams[param]?.toString() ?? ''
    }), {})
  }

  protected getQueryParams (filters: FiltersConfig | null, query: QueryParams): Location['query'] {
    return this.translateQueryParamsToQuery(filters
      ? composeQueryParams({
        ...parseQueryParams(query),
        ...filters,
        ...this.overrideParams
      })
      : query
    )
  }

  protected clearFilters (
    params: FilterParamConfig[],
    filters: FiltersConfig | null = null
  ): FiltersConfig {
    return params.reduce((prev, p) => ({
      ...prev,
      [p.queryParam]: p.isAttribute ? (p.isMulti ? [] : '') : filters ? filters[p.queryParam] : ''
    }), {})
  }

  private getParamConfig (params: FilterParamConfig[], param: string) {
    return params.find(p => p.queryParam === param)
  }
}

export default FiltersHandlerMixin
