
import { AsyncComponent } from 'vue'

import { FilterLayout } from '../Filters/Filters.config'

export enum ToggleFilterIcon {
  Hide = 'hide',
  Show = 'show'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const listDisplayControlsComponentsRegistry: Record<string, AsyncComponent> = {
  [FilterLayout.Accordion]: () => import(/* webpackChunkName: "shared/ClosableControls" */ './partials/ClosableControls.vue'),
  [FilterLayout.Popover]: () => import(/* webpackChunkName: "shared/PopoverControls" */ './partials/PopoverControls.vue')
}
