
























import { Component, Inject, Prop } from 'vue-property-decorator'
import { Filter } from '@movecloser/front-core'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'
import { FilterParamConfig } from '../../../../../contexts'
import { FiltersHandlerMixin } from '../../../../../support/mixins/FiltersHandler.mixin.vue'

import { FilterList } from '../../../molecules/FilterList'

import FilterPopover from '../../Filters/partials/FilterPopover/FilterPopover.vue'
import { FilterPopoverProps } from '../../Filters/partials/FilterPopover/FilterPopover.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ControlItem>({
  name: 'ControlItem',
  components: { FilterList, FilterPopover },
  mounted () {
    /**
     * @inheritDoc
     */
    this.initLabels()
  }
})
export class ControlItem extends FiltersHandlerMixin {
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public isMobile!: () => boolean

  @Prop({ required: true, type: Object })
  public param!: FilterParamConfig

  @Prop({ required: true })
  public filter!: Filter

  public currentFilter: FilterPopoverProps['filter'] = this.filter ?? null

  public open: boolean = false
  public label: string | undefined = ''

  public get triggerLabel (): string | undefined {
    return this.label
  }

  public get isOpen (): boolean {
    return this.open
  }

  /**
   * Concat label with current value of the filter
   */
  public initLabels (): void {
    if (!this.currentFilter) {
      this.label = String(this.param.label)
    } else {
      for (const [k, v] of Object.entries(this.param.options)) {
        if (k === this.filter) {
          /**
           * TODO: Test this in front App "isMobile()"
           */
          if (this.isMobile()) {
            this.label = `${v}`
          } else {
            this.label = `${this.param.label} ${v}`
          }
        }
      }
    }
  }

  /**
   * Set new filter
   * @param newFilter
   */
  public setFilter (newFilter: Filter) {
    this.$emit('update', { filter: newFilter, queryParam: this.param.queryParam })

    /** update current filter */
    this.currentFilter = String(newFilter)

    /** update label */
    for (const [k, v] of Object.entries(this.param.options)) {
      if (k === newFilter) {
        this.label = `${this.param.label} ${v}`
      }
    }

    /** close picker */
    this.open = false
  }
}

export default ControlItem
