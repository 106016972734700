
import { AsyncComponent } from 'vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum FilterLayout {
  Accordion = 'accordion',
  Popover = 'popover'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const filterLayoutsRegistry: Record<string, AsyncComponent> = {
  [FilterLayout.Accordion]: () => import(/* webpackChunkName: "shared/FilterAccordion" */ './partials/FilterAccordion/FilterAccordion.vue'),
  [FilterLayout.Popover]: () => import(/* webpackChunkName: "shared/FilterPopoverList" */ './partials/FilterPopoverList/FilterPopoverList.vue')
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface FiltersComponentConfig {
  icons: {
    accordionIcon: string
    closeIcon: string
  }
  layout: string
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const FILTERS_COMPONENT_TYPE_KEY = 'Filters'

/**
 * Component configuration for app
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const filtersComponentConfigMap = {
  icons: {
    accordionIcon: 'ArrowDownIcon',
    closeIcon: 'CloseIcon'
  },
  layout: 'popover'
}
